<template>
    <div class="unlink" id="accounts">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <card type="filter default" >
                        <template slot="header">
                            <p class="title-1">{{$t('desvinculacion.title')}}</p>
                            <router-link :to="$i18nRoute({name: 'Accounts'})" data-dismiss="modal" type="button">
                                <font-awesome-icon icon="plus-square" size="1x"/>
                                {{$t('navbar.modal.button1')}}
                            </router-link>
                        </template>
                        <p>{{$t('desvinculacion.descripcion')}}</p>
                        <div class="change-tabs">
                            <ul class="nav nav-tabs" id="myTab1" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile12" role="tab" aria-controls="profile1" aria-selected="false">{{$t('navbar.modal.label2')}}</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="home-tab" data-toggle="tab" href="#home1" role="tab" aria-controls="home" aria-selected="true">{{$t('navbar.modal.label1')}}</a>
                                </li>
                            </ul>
                            <div class="form-control">
                            {{$t('navbar.modal.label3')}}
                            </div>
                            <div class="tab-content" id="myTabContent1">
                                <div class="tab-pane fade" id="home1" role="tabpanel" aria-labelledby="home-tab">
                                    No information
                                </div>
                                <div class="tab-pane fade show active" id="profile12" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="form-group" v-for="socials1 in empresa" :key="socials1.Id_Empresa">
                                    <label :for="'unlink'+socials1.Id_Empresa" class="label-cbx">
                                        <input name="empresaunlink" :id="'unlink'+socials1.Id_Empresa" @click="showRedes(socials1.Id_Empresa)" type="radio" class="d-none" >
                                        <div class="checkbox">
                                        <svg width="20px" height="20px" viewBox="0 0 20 20"><path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path><polyline points="4 11 8 15 16 6"></polyline></svg>
                                        </div> 
                                        {{ socials1.nombre }}
                                    </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
                <div class="col-12 col-lg-6">
                    <card type="filter default" >
                        <!-- v-for cuentas -->
                        <div class="row2">
                            <transition name="slide-fade">
                                <div class="pdas" v-if="redes">
                                    <div class="content-crt" >
                                        <div  class="form-gruop" v-for="(item, index) in redes" :key="index" style="display: inline-block;min-width: 100px;text-align: center;">
                                            <div class="cntr">
                                                <label :for="item.Red_Social" class="label-cbx">
                                                    <div class="icons">
                                                        <span :class="'fab fa-'+item.Red_Social.toLowerCase()"></span>
                                                    </div>
                                                    <p>{{item.Red_Social}}</p>
                                                    <p class="post-total">{{item.Id_Usuario}}</p>
                                                    <input v-if="item.Red_Social == 'Facebook'" :checked="cheFacebook" :value="cheFacebook" v-model="cheFacebook" :name="item.Red_Social" :id="item.Red_Social"  type="checkbox" class="invisible roeuba">
                                                    <input v-else-if="item.Red_Social == 'Instagram'" :checked="cheInstagram" v-model="cheInstagram" :value="cheInstagram" :name="item.Red_Social" :id="item.Red_Social"  type="checkbox" class="invisible">
                                                    <input v-else :name="item.Red_Social" :id="item.Red_Social" :checked="cheTwitter" v-model="cheTwitter" :value="cheTwitter" type="checkbox"  class="invisible" >
                                                    <div class="checkbox">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20">
                                                            <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
                                                            <polyline points="4 11 8 15 16 6"></polyline>
                                                        </svg>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group btn-groups">
                                        <button class="btn-blue" @click="resynchronize()">{{$t('desvinculacion.button1')}}</button>
                                        <button class="btn-blue" @click="stopSyncing()">{{$t('desvinculacion.button2')}}</button>
                                        <button class="btn-blue" @click="unlink">{{$t('desvinculacion.button3')}}</button>
                                    </div>
                                </div>
                            </transition >
                            <div class="prueb" v-if="redes == null">
                                <p >{{$t('desvinculacion.text1')}}</p>
                            </div>
                            <div class="prueba" v-if="redes == false">
                                <p>{{$t('desvinculacion.text2')}}</p>    
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Card from "@/components/card/Card.component.vue";
export default {
    name: "Unlink",
  components: {
    Card
  },
  data() {
    return {
      redes: null,
      empresa: null,
      cheFacebook: false,
      cheInstagram: false,
      cheTwitter:  false,
      empresaid: null
    }
  },
    computed: {
    ...mapState(["social"]),
    ...mapState('user',["users", 'company']),
  },  
  created() {
    // this.Carga()
    this.getCompany()
    console.log(this.redes);
  },
  methods: {
      ...mapMutations(["Carga", 'Counts']),
      ...mapActions('user', ['getRedes', 'uploadCompany']),
      ...mapActions(["socialWeb"]),
    async getCompany(){
        const { data } = await this.axios.get(`/bextsocial/companyuser/${this.users[0].Id_Usuario}`)
        this.empresa = data
    },
    async showRedes(idempresa){
        if(idempresa){
            const { data } = await this.axios.get(`/bextsocial/cuentacompany/${idempresa}`)
            this.redes = data
        }
        this.empresaid = idempresa
        
    },
    async unlink(){
        // in any component
        this.$fire({
            title: "Desvincular cuenta de Bextsocial",
            text: "text",
            type: "info",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'DESVINCULAR',
            cancelButtonText: 'CANCELAR',
            // showCloseButton: true,
            // progressSteps,
            // currentProgressStep,
            // animation: false,
            // customClass: {
            //     popup: "swal2-animate-error-x-mark"
            // },       
            html: "<p>Estas a punto de desvincular esta cuenta de nuestra plataforma. ¿Estás seguro de desvincularla?.</p>"
        }).then(async r => {
            console.log(r.value);
            if(r.value == true){
                this.Carga()
                let msg = '';
                let punto = [];
                
                if(this.cheFacebook){
                    await this.axios.post('/bextsocial/syncaccounts/removefb', {
                        id_empresa: this.empresaid,
                    });
                    punto.push('Facebook')                    
                }
                if(this.cheInstagram){
                    await this.axios.post('/bextsocial/syncaccounts/removeinstagram', {
                        id_empresa: this.empresaid,
                    });
                    punto.push('Instragram')
                    
                }
                if(this.cheTwitter){
                    await this.axios.post('/bextsocial/syncaccounts/removetwitter', {
                        id_empresa: this.empresaid,
                    });
                    punto.push('Twitter')
                }
                if(punto.length > 1){
                    if(this.cheFacebook && this.cheTwitter && this.cheInstagram){
                        msg = 'Ha desvinculado todos sus redes sociales, ahora debes sincronizar de nuevo las cuentas'
                    }
                    else{
                        punto.forEach((x, i) => {
                            if(i < 1){
                                msg = 'Ház desvinculado las cuentas de "'+x+'", puedes volver a vincularla dando clic aquí.'
                            }
                            else{                            
                                msg = msg.split(",");
                                msg= msg[0]+ ' y de "'+x+'", '+msg[1]
                            }
                        })
                    }                    
                }
                else{
                    msg= 'Ház desvinculado la cuenta de "'+punto[0]+'", puedes volver a vincularla dando clic aquí.'
                }
                const socialRed = await this.getRedes()
                if(socialRed != false){
                    this.socialWeb(socialRed)
                    this.showRedes(socialRed[0].Id_Empresa)
                    this.$fire({
                        title: "¡Perfecto!",
                        type: "info",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: 'OK',
                        html: "<p>'"+msg+"'</p>"
                    })
                }
                else{
                    this.socialWeb(false)
                    this.redes = false
                    this.$fire({
                        title: "¡Perfecto!",
                        type: "info",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: 'OK',
                        html: "<p>'"+msg+"'</p>"
                    }).then(async r => {
                        debugger
                        this.uploadCompany(this.empresaid)
                        this.Counts(false)
                        this.$router.push({ name: "Sync" });
                        // this.$fire({
                        // title: "¡Perfecto!",
                        // type: "info",
                        // showCancelButton: true,
                        // showConfirmButton: false,
                        // cancelButtonText: 'OK',
                        // html: "<p>Ya no tienes cuentas sincronizadas, si eres una cuenta gratuita para poder volver a sincronizar debes comprar una suscripción.</p>"
                        // }).then(async r => {
                        //     this.$alert('')
                        // })                        
                    })


                }
                this.Carga()
            }
        });
        
    },
  }
}

</script>